@import url("https://fonts.googleapis.com/css2?family=DM+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500&display=swap");
$modalBackdrop: rgba(0, 0, 0, 0.4);
$borderColor: rgba(0, 0, 0, 0.25);
$blue: #2b78ff;
$lightBlue: rgba(219, 233, 255, 0.7);
$bgBlue: #dbe9ff8a;
$orange: #ff772b;
$lightOrange: #fff2ea;
$white: #ffffff;
$grey: #746e6e;
$darkbg: rgba(29, 53, 87, 1);
$tablehead: #b9d8f9;
$tablebody: rgba(219, 233, 255, 0.25);
$headerHeight: 65px;
$largeMenuWidth: 300px;
$smallMenuWidth: 79px;
$whiteLowOpacity: rgba(255, 255, 255, 0.15);

////////////////////////////////////////PLACEHOLDER CSS///////////////////////////////////////////////
::placeholder {
  color: $modalBackdrop !important;
  line-height: 18px !important;
}

////////////////////////////////////////SCROLLBAR CSS/////////////////////////////////////////////////
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: $darkbg;

  &:hover {
    background: #888;
  }
}

///////////////////////////////////////////// COMMON CSS /////////////////////////////////////////////
html,
body,
#root,
.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

body {
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
}

select {
  optgroup {
    font-weight: 100 !important;
    color: #00000073;
    font-size: 11px;
  }

  option {
    font-size: 14px;
    color: #000;
  }
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.w-60per {
  width: 60%;
}

.modal {
  display: block !important;
  background: $modalBackdrop;
}

.orange-head {
  color: $orange;
}

.blue-color {
  color: $blue;
}

.form-control,
.form-select {
  background-color: $lightBlue !important;
  border-radius: 6px;
  border: 0 !important;
  margin-top: 7px;
  padding: 10px;

  // max-width: 240px;
  // width: 100%;
  &:focus {
    outline: none !important;
    box-shadow: none;
  }

  .form-select-lg {
    line-height: 18px;
    margin-bottom: 10px;
    font-size: 11px;
  }
}

.white-form-control,
.white-form-select {
  background-color: white !important;
  border-radius: 6px;
  border: 0 !important;
  margin-top: 7px;
  padding: 10px;

  // max-width: 240px;
  // width: 100%;
  &:focus {
    outline: none !important;
    box-shadow: none;
  }

  .form-select-lg {
    line-height: 18px;
    margin-bottom: 10px;
    font-size: 11px;
  }
}

.multi-select-btn {
  max-width: none !important;
}

.submit {
  border-radius: 12px;
  background-color: $blue;
  color: $white;
  padding: 10px;
  max-width: 150px !important;
  width: 100%;

  &:hover {
    border: 1px solid $blue;
    color: $blue;
  }
}

.btnsync {
  color: $white;

  &:hover {
    background-color: $white !important;
    border: 1px solid $blue !important;
    color: $blue;
  }
}

.debtormainsync{
  border: 1px solid white;
   padding: 15px 10px;
    background-color: #2b78ff;
     max-width: 150px;
      width: 100%;
}

.max-w-0 {
  max-width: none !important;
}

.collection-box {
  background: $white;
  box-shadow: 0px 4px 35px $borderColor;
  border-radius: 12px;
}

.blue-box {
  background-color: $lightBlue;
  border-radius: 12px;
  height: 300px;

  p {
    padding: 15px 10px;
    background: $lightOrange;
    margin-top: 10px;
    border-radius: 12px;
    color: $orange;
  }

  .upload-csv {
    border: 2px dotted $modalBackdrop;
    position: relative;
    top: 35%;

    label {
      cursor: pointer;
      color: $modalBackdrop;
      font-size: 14px !important;

      i {
        font-size: 30px;
      }

      span {
        top: -5px;
        position: relative;
      }
    }

    input {
      display: none;
    }
  }
}

.btn-grp-own {
  width: 100%;
  max-width: 760px;

  .btn {
    padding: 16px 5px;
    border: none !important;
    width: calc(100% / 4);
    font-size: 12px;
  }
}

//////////////////////////////////CARD CSS//////////////////////////////////////////////////////////
.card {
  .card-body {
    height: 160px;
    overflow-y: scroll;

    a {
      text-decoration: none;
      color: #212529 !important;
    }

    p {
      cursor: pointer;

      &:hover {
        color: $orange;
      }
    }
  }

  .card-header {
    background-color: $darkbg;
    color: $white;
  }
}

//////////////////////////////LANDING PAGE////////////////////////////////////////////////////////
.landing-page {
  .module-icons {
    width: 16px;
  }

  .landing-page-header {
    width: 25px;
  }
}

///////////////////////////////////////////// table css /////////////////////////////////////////////
.collection-table {
  table {
    // width: 60%;

    thead {
      background: $tablehead;
      text-align: left !important;

      th {
        padding: 20px;
      }

      th:first-child {
        border-top-left-radius: 15px;
      }

      th:last-child {
        border-top-right-radius: 15px;
      }
    }

    tbody {
      background: $tablebody;

      td {
        border-bottom: 0 !important;
        padding: 20px !important;
      }

      tr {
        &:first-child {
          color: $grey;
          font-weight: normal;
          border-bottom: solid 1px $borderColor;
          height: 70px !important;
        }

        .table-icon {
          border: 0;
          padding: 0;
          background-color: transparent;

          &:hover {
            background-color: transparent;
          }
        }
      }

      tr:nth-last-child(1) {
        td:first-child {
          border-bottom-left-radius: 15px;
        }

        td:last-child {
          border-bottom-right-radius: 15px;
        }
      }
    }
  }
}

.table-other {
  font-size: 12px !important;

  th {
    color: rgba(0, 0, 0, 0.65) !important;
    font-weight: normal !important;
    text-align: center;
  }

  .logs-th,
  .logs-td {
    width: 350px !important;
  }
}

.location-table {
  td:nth-child(3) {
    max-width: 250px !important;
    white-space: normal !important;
  }
}

///////////////////////////////////////////// TAB CSS /////////////////////////////////////////////
.rule-tab {
  h3 {
    font-weight: bold !important;
  }

  .nav-tabs {
    border-bottom: 0 !important;

    .nav-link {
      border-top: 0 !important;
      border-left: 0 !important;
      border-right: 0 !important;
      border-bottom: solid 1px $borderColor !important;
      color: $modalBackdrop;
      cursor: pointer;
      text-align: center;
    }

    .active {
      color: $blue !important;
      border-bottom: 3px solid $blue !important;
    }
  }

  .config-body {
    padding: 30px;
    border-radius: 12px;
    background: $bgBlue;

    .form-control {
      max-width: 240px;
      width: 100%;
    }
  }

  .addMore {
    color: $blue;
  }

  .remove {
    color: $orange;
    background-color: $lightOrange !important;
  }
}

///////////////////////////////////////////// LOGIN CSS /////////////////////////////////////////////
.collection-login {
  .reset-pass {
    border-right: 1px solid $borderColor !important;
  }

  input {
    margin-top: 0 !important;
    padding: 15px 10px;
    border: 1px solid $borderColor !important;
    border-right: 0 !important;
    background: $white !important;
  }

  .input-group {
    width: 100%;

    .input-group-text {
      border: 1px solid $borderColor !important;
      background-color: $white;

      .bi-eye,
      .bi-eye-slash {
        &:hover {
          color: $blue;
        }
      }
    }
  }

  .btn {
    padding: 10px;
  }
}
.resetpagelink{
  color: blue;
  padding-top: 20px;
  cursor: pointer;
}

////////////////////////////////////SIDENAV CSS/////////////////////////////////
.sidenav {
  width: 270px;
  height: 100%;
  background-color: $darkbg;
  margin-left: 0 !important;
}

.side-nav-tree {
  border-left: 2px solid #F6F6F629;
  position: absolute;
  top: 0;
  bottom: 25%;
  margin-left: 18px;
}

.side-nav-branch {
  transform: scaleX(3);
  padding: 0 0 0 3px !important;
  color: #F6F6F629 !important;
  font-weight: 900;
}

.dashboard-right {
  height: 100%;
  position: relative;
  padding-right: 0 !important;
  padding-left: 0 !important;

  .mobile-dashboard-header {
    background-color: $bgBlue !important;
    box-shadow: 0px 1px 5px $modalBackdrop;
    height: $headerHeight;
    padding: calc(#{$headerHeight} / 4);
    display: flex;

    .rightSection {
      flex-basis: 100%;
      flex: 1 1 25%;
      text-align: right;
    }
  }

  .dashboard-body {
    padding: 15px;
    overflow: auto;
    background-position: right 156px;
  }

  .bi-house-fill {
    font-size: 25px;
    // background: -webkit-linear-gradient($blue, $darkBg);
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
    color: $darkbg;
  }

  .nav {
    .nav-item {
      img {
        width: 25px;
      }
    }
  }
}

.dashboard-menu {
  height: 100%;
  padding: 15px 9px;
  white-space: nowrap;
  overflow: auto;
  overflow-x: hidden;

  .menu-control-icon {
    line-height: 30px;
    cursor: pointer;
    font-size: 18px;
    color: #fff;
    text-align: center;
  }

  .menu-items {
    color: $white;
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      line-height: 1;
      font-size: 14px;
      cursor: pointer;

      img {
        width: 16px;
      }

      span {
        display: inline-block;
        padding-left: 20px;
        color: $white;
      }

      a,
      button {
        color: $white;
        border: none;
        background: transparent;
        padding: 0;

        .fa {
          vertical-align: top;
          text-align: center;
          min-width: 14px;
        }
      }

      a.active {
        opacity: 0.7;
        color: #00ff3a;
      }

      .multi-collapse {
        list-style-type: none;

        li {
          padding: 12px 0;
          border-left-color: #f6f6f629;

          &:first-child {
            padding-top: 15px;
          }
        }
      }
    }

    .collapse-head {
      color: $white;
    }
  }
}

.menu-large {
  // border-radius: 0px 0px 32px 0px;
  width: $largeMenuWidth !important;
  transition: 0.3s;

  .fa {
    min-width: 16px;
  }

  .logo {
    height: 40px;
    margin-top: 10px;
  }

  .menu-items {
    li {
      border-radius: 30px;
      padding: 15px 0px;
    }
  }

  .language-setting-main {
    .text {
      text-align: left;
    }
  }

  .collapse-head:not(.collapsed) {
    background-color: $whiteLowOpacity;
    border-radius: 12px;
    width: 100%;
    text-align: left;
    height: 45px;
    padding: 10px;
  }
}

.menu-small {
  border-radius: 0px;
  width: $smallMenuWidth !important;
  transition: 0.2s;

  .menu-control-icon {
    width: 100%;
  }

  .logo {
    height: auto;
    margin: 0 auto;
    display: block;
  }

  .menu-items {
    li {
      border-radius: 15px;
      padding: 15px 0px;
      text-align: center;

      // span {
      //   display: none;
      // }
      .side-menu-bg {
        background: rgba(255, 255, 255, 0.15);
        transition: background-color 0.2s ease-out;
      }

      .side-img {
        border-radius: 12px;
        // background: rgba(255, 255, 255, 0.15);
        padding: 12px 16px;

      }
    }
  }

  .language-setting-main {
    .text {
      text-align: center;
    }
  }
}

.blueTheme {

  // .mobile-dashboard-header,
  .cred-btn,
  .carepay-table th,
  .dashboard-menu,
  .mobile-menu-header,
  .border-bottom-heading,
  .accordion-button,
  .choose-file-input span,
  .detail-page-hr-tabs li {
    background-color: $darkbg;
    // background-image: linear-gradient(315deg, #045de9 0%, #09c6f9 74%);
  }

  .detail-page-heading,
  .choose-file-input .fa,
  .com-temp-heading {
    color: #00ff3a;
  }

  .detail-page-hr-sub-tabs li.active {
    border-bottom: 3px solid #00ff3a;
    background-color: black;
    color: #fff;
  }
}

// //////////////////////////////LEAD ALLOCATION AND LISTING///////////////////////////////////////////

.disabledDiv {
  pointer-events: none;
  opacity: 0.4;
}

.selectbox-bg {
  background-color: #f6f9ff;
  padding: 20px;
  min-width: 350px;
  max-width: 50%;
  border-radius: 6px;
}

.selectbox-bg-big {
  background-color: #f6f9ff;
  padding: 20px;
  min-width: 560px;
  max-width: 80%;
  border-radius: 6px;
}

.hr-light {
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.list-table {
  min-height: 700px;
  background-color: "#DBE9FF40";

  .list-heading {
    background-color: #b9d8f973;
  }
}

.redPill {
  background-color: #ffe1dba6;
  color: #e63946;
  text-align: center;
  width: 86px;
  padding: 5px 12px;
  border-radius: 6px;
  font-weight: 500;
  margin: auto;
}

.yellowPill {
  background-color: #fff5e1;
  color: #ffb828;
  text-align: center;
  width: 86px;
  padding: 5px 12px;
  border-radius: 6px;
  font-weight: 500;
  margin: auto;
}

.greenPill {
  background-color: #e1f6ec;
  color: #4cc78c;
  text-align: center;
  width: 86px;
  padding: 5px 12px;
  border-radius: 6px;
  font-weight: 500;
  margin: auto;
}

.filterText {
  color: $blue;
  font-size: 16px;

  &:hover {
    cursor: pointer;
  }
}

.modal-header {
  border: none;
}

.modal-footer {
  border: none;
}

.modal-dialog {
  min-width: 500px;
  max-width: 30%;
  margin-right: auto;
  margin-left: auto;
}

.bottom {
  border-bottom: 1px solid gray;
}

.sub-heading {
  font-size: 11px;
  color: "#00000073";
  font-weight: 400;
}

.customCheckbox {
  width: 20px;
}

.dpdVal {
  width: fit-content;
  cursor: pointer;
  height: 30px;
  line-height: 15px;
  background-color: #dbe9ffb2;
  margin-right: 10px;
  padding: 6px, 12px, 6px, 12px;
  border-radius: 4px;
}

.active-filters {
  width: fit-content;
  height: 30px;
  cursor: pointer;
  line-height: 15px;
  background-color: #2b78ff;
  margin-right: 10px;
  padding: 6px, 12px, 6px, 12px;
  border-radius: 4px;
  color: white;
}

.chip {
  white-space: wrap !important;
}

.manualAllocationListing {
  tr {
    &:first-child {
      color: #000 !important;
      font-weight: normal;
      border-bottom: solid 0px $borderColor !important;
      height: 70px !important;
    }

    tr:nth-last-child(1) {
      td:first-child {
        border-bottom-left-radius: 15px;
      }

      td:last-child {
        border-bottom-right-radius: 15px;
      }
    }
  }
}

.page-box {
  border: 1px solid gray;
  border-radius: 6px;
  padding: 5px;
}

/////////////////////////////////////////////DEBTOR LISTING AND DETAILS//////////////////////////////////

.debitor-search {
  .css-b62m3t-container {
    width: 100% !important;
  }
}

.react-datepicker-wrapper {
  min-width: 190px;
  max-width: 100%;
}

.financial-switch {
  .switch-left {
    background-color: #1d3557;
    color: white;
    width: 150px;
    height: 50px;
    border-radius: 12px 0px 0px 12px;
    cursor: pointer;
    font-weight: 500;
  }

  .switch-right {
    background-color: #f2f2fa;
    color: black;
    width: 150px;
    height: 50px;
    border-radius: 0 12px 12px 0;
    cursor: pointer;
    font-weight: 500;
  }
}

//////////////////////////////////////////// DEBTOR DETAILS ////////////////////////////////////////////

.debtor-info {
  .right-panel {
    min-height: 100%;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: var(--light-grey-2, #f2f2fa);
  }

  .left-panel {
    border-radius: 6px;
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    .heading {
      background: #1d3557;
      height: 35px;
      width: 100%;
      padding-left: 20px;
    }

    .first-heading {
      border-radius: 6px 6px 0 0;
      background: #1d3557;
      height: 35px;
      width: 100%;
      padding-left: 20px;
    }
  }
}

.document {
  width: 600px;
  height: 500px;
  border-radius: 12px;
  background: rgba(219, 233, 255, 0.7);
}

.sqr-icon {
  border: 1px solid;
  width: 18px;
  height: 18px;
  padding-left: 3px;
  padding-right: 3px;
  /* padding: 2px; */
  font-size: 11px;
  border-radius: 2px;
}

//////////////////////////////////////////////DEBTORS LIST///////////////////////////////////////////////4

.mailBody {
  min-height: 300px;
  border-radius: 3px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;

  .heading {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
    background-color: #e9ecf5;
    height: 40px;
    margin-top: 4px;
  }

  .ql-container {
    box-sizing: border-box;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 13px;
    height: 300px !important;
    margin: 0px;
    position: relative;
  }
}

//////////////////////////////////////////EMPLOYEE LIST/////////////////////////////////////////
.employee-buttons {
  .onboard-btn {
    border-radius: 12px;
    background-color: $blue;
    color: $white;
    padding: 10px;
    max-width: 200px !important;
    width: 100%;

    &:hover {
      border: 1px solid $blue;
      color: $blue;
      background-color: transparent;
    }
  }

  .allocate-btn {
    border-radius: 12px;
    background-color: transparent;
    border: 1px solid $blue;
    color: $blue;
    padding: 10px;
    max-width: 200px !important;
    width: 100%;

    &:hover {
      color: white !important;
      background-color: $blue;
    }
  }

  .allocate-btn-sm {
    border-radius: 12px;
    background-color: transparent;
    border: 1px solid $blue;
    color: $blue;
    padding: 10px;
    max-width: 150px !important;
    width: 100%;

    &:hover {
      color: white !important;
      background-color: $blue;
    }
  }
}

.eye-holder {
  background-color: $blue;
  border-radius: 50%;
  font-weight: bolder;
  color: white;
  width: 30px;
  height: 30px;
  text-align: center;
  align-self: center;
  padding-top: 5px;
}

//////////////////////////////////////////////// ACCESS MANAGEMENT /////////////////////////////////////
.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
  min-height: 30px !important;
}

.css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
  margin: 0 0 !important;
  border-bottom: 1px solid #0000001a;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  box-shadow: none !important;
  margin: 0 0 !important;
}

.accord-row {
  .entry {
    font-size: 14px;
    color: #000000a6;
    margin-left: 0 !important;
  }
}

.assignedRole {
  min-height: 250px;
  border-radius: 6px;
  background: rgba(219, 233, 255, 0.45);

  .heading {
    display: flex;
    height: 45px;
    padding: 10px 15px 10px 17px;
    justify-content: space-between;
    align-items: center;
    border-radius: 6px 6px 0px 0px;
    background: rgba(168, 200, 255, 0.7);
  }
}

.assignedRoleTable {
  background-color: #fff;
  border: none;
  min-height: 150px;

  // width: fit-content;
  .role-table-items {
    min-width: 170px;
    border: 0.5px solid rgba(0, 0, 0, 0.25);

    .heading {
      height: 35px;
      font-weight: 500;
      border-radius: 0;
      border-bottom: 0.5px solid rgba(0, 0, 0, 0.25);
    }
  }
}

/////////////////////////////////////////////BULK COMMUNICATION/////////////////////////////////////////

.csvUpload {
  .blueBox {
    background-color: $lightBlue;
    border-radius: 12px;
    min-height: 300px !important;

    p {
      padding: 15px 10px;
      background: $lightOrange;
      margin-top: 10px;
      border-radius: 12px;
      color: $orange;
    }

    .upload-csv {
      border: 2px dotted $modalBackdrop;
      position: relative;
      top: 35%;

      label {
        cursor: pointer;
        color: $modalBackdrop;
        font-size: 14px !important;

        i {
          font-size: 30px;
        }

        span {
          top: -5px;
          position: relative;
        }
      }

      input {
        display: none;
      }
    }
  }
}

.notificationBox {
  border-radius: 6px;
  background: var(--light-grey-2, #f2f2fa);
}

.chatBox {
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  height: 450px;
  position: relative;

  .chatArea {
    padding: 20px 20px;
    height: 350px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
  }

  .sendBox {
    position: absolute;
    width: 100%;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    border-radius: 0px 0px 6px 6px;
    padding: 6px 21.313px 6.38px 15.938px;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    background: var(--light-grey-1, #e9ecf5);
    height: 50px;

    // max-height: 100px;
    .attachment {
      color: "#1D3557";
      transform: skew(-42deg, 30deg);
      align-self: center;
    }

    .send-btn {
      color: #267dff;
      cursor: pointer;
      transform: skew(-45deg, 45deg);
      align-self: center;
    }

    .text-box {
      width: 85%;
      border: none;
      padding-top: 8px;
      background: var(--light-grey-1, #e9ecf5);
      resize: none;

      &:focus {
        outline: none !important;
      }
    }
  }

  .msg-sender {
    display: flex;
    justify-content: end;
    margin: 5px 0;
  }

  .msg-receiver {
    display: flex;
    justify-content: start;
    margin: 5px 0;
  }

  .sender-first-msg {
    max-width: 50%;
    width: fit-content;
    min-height: 30px;
    border-radius: 6px 0px 6px 6px;
    background: #509de1;
    color: white;
    padding: 5px;
    word-wrap: break-all;
  }

  .receiver-first-msg {
    max-width: 50%;
    width: fit-content;
    min-height: 30px;
    border-radius: 0px 6px 6px 6px;
    background: #dbe9ff;
    padding: 5px;
    word-break: break-all;
  }
}

/////////////////////////////////////////// CUSTOM LOADER ///////////////////////////////////////////////
.custom-loader {
  width: 50px;
  height: 50px;
  display: grid;
}

.custom-loader::before,
.custom-loader::after {
  content: "";
  grid-area: 1/1;
  --c: radial-gradient(farthest-side, #2b78ff 92%, #0000);
  background: var(--c) 50% 0, var(--c) 50% 100%, var(--c) 100% 50%,
    var(--c) 0 50%;
  background-size: 12px 12px;
  background-repeat: no-repeat;
  animation: s2 1s infinite;
}

.custom-loader::before {
  margin: 4px;
  filter: hue-rotate(45deg);
  background-size: 8px 8px;
  animation-timing-function: linear;
}

@keyframes s2 {
  100% {
    transform: rotate(0.5turn);
  }
}

////////////////////////////// LOGOUT /////////////////////////////

.logout-popup {
  background: transparent;
  width: 150px;
  right: 15px;
  top: 42px;

  .logout-box {
    width: 100px;
    color: #1d3557;
    font-weight: 700;
    cursor: pointer;
    float: right;
  }
}

///////////////////////////////////////////// MEDIA QUERIES /////////////////////////////////////////////

@media (min-width: 1800px) {
  .container {
    max-width: 2200px;
  }

  // body,
  // h6,
  // h5,
  // h4,
  // h3,
  // h2,
  // h1,
  // p {
  //   font-size: 120%;
  // }
}

@media (max-width: 992px) {
  .rule-tab {
    .nav-tabs {
      .nav-link {
        height: 60px !important;
      }
    }
  }

  .collection-box {
    width: 100% !important;
  }

  .communicationBox {
    .form-control {
      width: 100% !important;
    }

    .form-select {
      width: 100% !important;
    }
  }
}

@media (max-width: 576px) {
  .rule-tab {
    h3 {
      font-size: 20px !important;
    }

    .nav-tabs {
      .nav-link {
        font-size: 11px !important;
      }
    }
  }

  .collection-box {
    width: 100% !important;
  }

  .chatBox {
    .sender-first-msg {
      max-width: 80% !important;
    }

    .receiver-first-msg {
      max-width: 80% !important;
    }
  }
}


.background-blur {
  position: fixed;
  background: rgba(0, 117, 153, 0.2);
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

button.dpd-btn.btn.submit {
  max-width: max-content !important;
}

.btn-grp-own {
  width: 100%;
  max-width: 760px;

  .btn {
    padding: 16px 5px;
    border: none !important;
    width: calc(100% / 4);
    font-size: 12px;
  }
}

.btn-grp-own {
  button.btn.custom-group-btn-border-right {
    border: 0;
    border-right: 1px solid #1D3557 !important;
    z-index: 3;
  }
}

.activity-circle {
  margin-top: 20px;
  width: 40px;
  height: 40px;
  background: #F1F7FF;
  color: #fff;
  // box-shadow: 6px 6px 20px rgba(255, 92, 93, 0.3);
  // background: linear-gradient(white, white) padding-box,
  //     linear-gradient(to bottom, #FF6A92, #6278E1) border-box;
  border-radius: 50em;
  border: 10px solid transparent;
  margin-left: auto;
  margin-right: auto;
}

//////////////////LMReport section ///////// 
.LMREPORT_section {
  .container1 {


    .rows1 {
      margin-top: 0px !important;
    }

    .row {
      .date-labels {
        color: #000;
        text-align: center;
        // font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
      }

      .datecols {
        margin-top: 16px;
      }

      .date-input {
        border: 1px solid #DDE1E6;
        border-radius: 6px;
        padding: 15px 35px;
        color: rgba(0, 0, 0, 0.45);
        font-weight: 400;
        line-height: 16px;
        margin-top: 8px;
        margin-right: 16px;
        // font-size: 14px;
      }

      .filterbtn {
        display: flex;
        align-items: flex-end;
        // margin-top: 48px;


      }

      @media (min-width: 1200px) {
        #cols {
          width: calc(100%/5) !important;
        }
      }

      .column {
        margin-top: 10px;
        border-radius: 6px;
        border: 2px solid #ECEEF6;
        padding: 20px 16px;
        background: #FFF;

        .row {
          .col {
            h5 {
              color: #1D3557;
              font-size: 14px;
              font-weight: 500;
            }
          }

          #trendingup,
          #trendingdown {
            border-radius: 12px;
            padding: 3px 5px;
            display: flex;

            .icon {
              width: 28px;
              height: 28px;
              margin-top: 5px;
              margin-right: 4px;
            }

            h5 {
              font-size: 15px;
              margin-top: 5px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              letter-spacing: 0.12px;
            }
          }

          #trendingup {
            background: #E0F0E4;

            h5 {
              color: #377E36;
            }
          }

          #trendingdown {
            background: #FEECEB;

            h5 {
              color: #B12F30;
            }
          }
        }

        .h5 {
          color: #1D3557;
          font-size: 28px;
          font-weight: 700;
          letter-spacing: 0.28px;
        }
      }
    }

    .row {
      margin-top: 20px;

      .col {
        .column2 {
          border-radius: 6px;
          border: 2px solid #ECEEF6;
          background: #FFF;
          padding: 10px;

          .row {
            .heading {
              display: flex;

              img {
                margin-right: 5px;
                width: 20px;
                margin-top: 4px;
                height: 20px;
              }

              h5 {
                margin-top: 4px;
                color: #1D3557;
                font-size: 16px;
                font-weight: 500;
                line-height: 18px;
                letter-spacing: -0.5px;
              }
            }

            .row {
              .piechart-container {
                z-index: 0;
                position: relative;

                .circleimg {
                  z-index: 1;
                  position: absolute;
                  top: 5rem;
                  margin-left: 4.5rem;
                }

                .text {
                  z-index: 2;
                  position: absolute;
                  top: 7.3rem;
                  margin-left: 7.5rem;
                  text-align: center;

                  h5 {
                    color: #1E265E;
                    font-size: 23px;
                    font-weight: 400;
                  }

                  p {
                    color: #1E265E;
                    font-size: 15px;
                    font-weight: 400;
                  }
                }
              }

              .legend {
                margin-top: 2rem;

                .firstlegend {
                  display: flex;
                  margin-top: 20px;

                  .first {
                    display: flex;

                    .circle {
                      width: 15px;
                      height: 15px;
                      margin-top: 3px;
                      margin-right: 15px;
                      border-radius: 50%;
                    }
                  }

                  .second {
                    p {
                      color: #1E265E;
                      font-size: 16px;
                      font-weight: 400;
                      margin-bottom: 0px !important;
                    }

                    h5 {
                      color: #1E265E;
                      font-size: 16px;
                      font-weight: 700;
                    }
                  }
                }
              }

              .row {
                margin-top: 20px;
                padding-left: 12px;

                .col {
                  border-radius: 6px;
                  padding-left: 0px;
                  padding-right: 0px;
                  border: 1px solid #ECEEF6;
                }
              }
            }
          }
        }
      }
    }
  }

  .contentoperation {
    #rowfirst {
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;

      .department_label {
        color: #000;
        font-size: 14px;
        // margin-top: 12px;
        font-weight: 400;
      }

      .selections {
        padding-top: 17.5px;
        max-width: 286px;
        padding-right: 48px;
        padding-bottom: 17.5px;
        margin-top: 8px;
        margin-right: 16px;
        border-radius: 6px;
        background-color: rgba(219, 233, 255, 0.70);
        border: 0px;

        color: #1D3557;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
      }

      .date-labels {
        color: #000;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
      }

      .datecols {
        margin-top: 16px;
      }

      .inputsfordate {
        border: 1px solid #DDE1E6;
        border-radius: 6px;
        padding: 15px 35px;
        color: rgba(0, 0, 0, 0.45);
        font-weight: 400;
        line-height: 16px;
        margin-top: 8px;
        margin-right: 16px;
        // font-size: 14px;
      }

      .filterbtn {
        display: flex;
        align-items: flex-end;
        // margin-top: 48px;

        .filter_Reset {
          margin-top: 10px;
          padding: 10px;
          border-radius: 6px;
          background-color: white;
          color: #267DFF;
          border: 2px solid #267DFF;
        }
      }


    }


    @media (min-width: 1200px) {
      #cols {
        width: calc(100%/5) !important;
      }

    }

    .column {
      margin-top: 10px;
      border-radius: 6px;
      border: 2px solid #ECEEF6;
      padding: 20px 16px;
      background: #FFF;

      .card5 {
        .card5col {
          h5 {
            color: #1D3557;
            font-size: 14px;
            font-weight: 500;
          }
        }



        .h5 {
          color: #1D3557;
          font-size: 28px;
          font-weight: 700;
          letter-spacing: 0.28px;
        }
      }

      #trendingup,
      #trendingdown {
        border-radius: 12px;
        padding: 3px 5px;
        display: flex;

        .icon {
          width: 28px;
          height: 28px;
          margin-top: 5px;
          margin-right: 4px;
        }

        h5 {
          font-size: 15px;
          margin-top: 5px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: 0.12px;
        }
      }

      #trendingup {
        background: #E0F0E4;

        h5 {
          color: #377E36;
        }
      }

      #trendingdown {
        background: #FEECEB;

        h5 {
          color: #B12F30;
        }
      }
    }


    #rowthird {
      margin-top: 20px;

      #positive {
        border-radius: 4px;
        background: #E0F0E4;
        padding: 2px 4px;
        margin-top: 8px;
        margin-bottom: 10px;
        color: #377E36;
        text-align: center;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
      }


      .colcharts {
        .column2 {
          border-radius: 6px;
          border: 2px solid #ECEEF6;
          background: #FFF;
          padding: 10px;

          #rowthird1 {
            margin-top: 0px !important;

            .chartcols {
              margin-top: 10px;
              padding-left: 22px;
              // border: 2px solid black;

              .rowchart {
                .chartsbar {
                  height: 300px;
                  padding-left: 6px;
                }
              }
            }

            .headingfirst {
              .circle {
                height: 6px;
                width: 6px;
                margin-top: 11px;
                margin-right: 8px;
                border-radius: 50%;
              }

              .rowss {
                margin-top: 0px;

                .colss {
                  display: flex;
                  padding-left: 0px;
                  padding-right: 0px;
                }

                .cols1 {
                  padding-left: 6px;
                  padding-right: 6px;

                  .blocks1 {
                    display: flex;

                    h5 {
                      color: var(--gray-400, #7A7A7A);
                      font-size: 14px;
                      font-weight: 500;
                      line-height: 18px;
                      margin-bottom: 0px;
                      letter-spacing: -0.5px;
                    }
                  }

                  .blocks2 {
                    display: flex;

                    h5 {
                      color: #1D3557;
                      font-size: 20px;
                      font-weight: 500;
                      margin-right: 8px;
                      line-height: 28px;
                      letter-spacing: -1px;
                    }
                  }
                }
              }

              img {
                margin-right: 5px;
                width: 20px;
                margin-top: 4px;
                height: 20px;
              }

              h5 {
                margin-top: 4px;
                color: #1D3557;
                font-size: 16px;
                font-weight: 500;
                line-height: 18px;
                letter-spacing: -0.5px;
              }

              .OAT {
                display: flex;
              }
            }

            .chartcols {
              #tablerow {
                margin-top: 10px;

                .columntable {
                  .tableemployee {
                    thead {
                      tr:nth-last-child(1) {
                        td:first-child {
                          border-top-left-radius: 6px;
                        }

                        td:last-child {
                          border-top-right-radius: 6px;
                        }
                      }
                    }

                    tbody {
                      tr:nth-child(odd) {
                        background-color: rgba(185, 216, 249, 0.25) !important;
                      }

                      tr:nth-last-child(1) {
                        td:first-child {
                          border-bottom-left-radius: 6px;
                        }

                        td:last-child {
                          border-bottom-right-radius: 6px;
                        }
                      }
                    }

                    #tablehead {
                      color: #746E6E;
                      text-align: center;
                      font-size: 14px;
                      font-weight: 500;
                      background-color: rgba(185, 216, 249, 0.45);
                      padding: 15px 19px;
                      line-height: 18px;

                    }

                    #tablevalue {
                      padding: 15px 19px;
                      color: #1D3557;
                      text-align: center;
                      // background-color: rgba(185, 216, 249, 0.15);
                      font-size: 12px;
                      font-weight: 400;
                      line-height: 18px;
                    }


                  }
                }
              }
            }

          }
        }
      }
    }
  }

  .tableFirst {
    margin: 0px;
    border-radius: 10px;
    overflow: hidden;
    text-align: center;
    border: 1px solid #ECEEF6;
  }

  #tablehead {
    color: #746E6E;
    padding: 20px;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    background-color: rgba(185, 216, 249, 0.45);
  }

  #tablevalue {
    padding: 20px;
    color: #000;
    background-color: rgba(185, 216, 249, 0.15);
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  #cols {
    .column .row {
      margin-top: 0px;
    }
  }
}

.Automatestart {
  padding: 5px 10px;
  border: 0;
  border-radius: 8px;
  background-color: green;
  color: white;
}

.Automatestop {
  padding: 5px 10px;
  border: 0;
  border-radius: 8px;
  background-color: red;
  color: white;
}

.reportingdashboard {

  .column {
    margin-top: 10px;
    border-radius: 6px;
    min-height: 116px;
    border: 2px solid #ECEEF6;
    padding: 20px 16px;
    background: #FFF;

    .row {
      .col {
        h5 {
          color: #1D3557;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }
}
.datasyncmodel{
  .row1{
    padding: 1rem 4rem;
    h5{
      font-size: 22px;
      font-weight: 700;
      color: rgba(35, 47, 99, 1);
    }
    .submitbtn{
      color: white;
      background-color: rgba(43, 120, 255, 1);
      border: 0px;
      border-radius: 12px;
      padding: 1rem 4.8rem;
      margin-top: 3.2rem;
    }
  }
}
.callIcon{
  background-color: rgba(76, 199, 140, 1);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding-left: 3px;
  padding-right: 3px;
  border: 0px;
}
.randomcallbox{
  height: 40px;
  border-radius: 8px;
  border: .5px solid black;
}
.activitybox{
  background-color: white;
  border-radius: 16px;
  padding: 26px 10px;
}
.colordropdown{
  width: 30px;
  height: .5px;
  padding: 0px 15px;
}
.datepickernew{
  .rmdp-container {
width: 100% !important;
input{
      width: 100% !important;
      background-color: rgba(219, 233, 255, 0.7) !important;
      border:0px;
      // padding-top: 22px;
      // padding-bottom: 22px;
      padding: 22px 10px;
    }
  }
}
.datepickernew2{
  .rmdp-container {
width: 100% !important;
input{
      width: 100% !important;
    }
  }
}

.filterboxes{
  border: 0px;
  .btn{
    padding: 10px;
    border: 0px;
  }
}